<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="120px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="设备名称">
                  <el-input clearable v-model="form.deviceName" placeholder="请输入设备名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="设备ID">
                  <el-input clearable v-model="form.deviceId" placeholder="请输入设备ID"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="设备类型">
                  <el-select clearable v-model="form.deviceType">
                    <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.DEVICE_TYPE" :key="`device_type_${index}`"></el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
					type="index"
          align="center"
					label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="deviceName"
          align="center"
					label="设备名称">
				</el-table-column>
				<el-table-column
					prop="deviceId"
          align="center"
					label="设备ID">
				</el-table-column>
        <el-table-column
					prop="deviceType"
          align="center"
					label="类型">
				</el-table-column>
        <el-table-column
					prop="triggerConditionTips"
          align="center"
					label="触发条件">
				</el-table-column>
        <el-table-column
					prop="alarmContent"
          align="center"
					label="告警内容">
				</el-table-column>
        <el-table-column
          align="center"
          width="300px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" @click="openDeleteDialog(scope.row.id)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <!-- <el-button class="default-button" @click="openDeleteDialog(selectedArr.map(item => item.id))">{{$t('delete')}}</el-button>
        <el-button class="default-button" @click="exportFunc(apis.Warehouse_ExportList)">{{$t('download')}}</el-button> -->
        <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import WarehouseSearchClass from './warehouseSearchClass'
import apis from '@/apis'
import mixin from '@/mixins/index'
import dictMixin from '@/mixins/dictMixin'
import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'

export default {
  components: {
    addDialog,
    confirmDialog,
  },
  mixins: [mixin, dictMixin],
  data() {
    return {
      form: new WarehouseSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.waringList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new WarehouseSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.waringRemove, this.confirmDialog.data).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      this.confirmDialog.data.id = [id]
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} id 选中的ID数组
     */
    openConfirmDialog(id) {
      this.confirmDialog.data.id = id
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data = []
        this.searchEvent()
      }
    },
  },
  activated() {
    this.getDevice()
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
