<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}告警`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <el-form ref="form" :model="form" label-width="130px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="设备列表">
              <el-transfer
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入设备名称"
                v-model="form.deviceId"
                :props="{
                  key: 'deviceId',
                  label: 'deviceName'
                }"
                :data="transferData">
              </el-transfer>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="警报类型">
              <el-select clearable v-model="form.alarmType" style="width:200px">
                <el-option :value="item.value" :label="item.label" v-for="(item, index) in DROPDOWNBOX.DEVICE_ALARM_TYPE" :key="`DEVICE_ALARM_TYPE_${index}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="当上述设备发生" prop="triggerCondition">
              <el-radio v-model="form.triggerCondition" label="1">大于</el-radio>
              <el-radio v-model="form.triggerCondition" label="2">小于</el-radio>
              <el-radio v-model="form.triggerCondition" label="3">区间</el-radio>
              <el-radio v-model="form.triggerCondition" label="4">等于</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="['','最大值', '最小值', '最小值', '等于'][Number(form.triggerCondition)]" prop="param1">
              <el-input clearable v-model="form.param1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最大值" prop="param2" v-if="form.triggerCondition === '3'">
              <el-input clearable v-model="form.param2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="告警内容" prop="alarmContent">
              <el-input clearable type="textarea" maxlength="100" v-model="form.alarmContent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import dictMixin from '@/mixins/dictMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin, dictMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
    transferData() {
      if (!this.isEdit) {
        return this.DROPDOWNBOX.device
      }
      const data = []
      for (const i of this.DROPDOWNBOX.device) {
        const newData = { ...i }
        newData.disabled = true
        data.push(newData)
      }
      return data
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.form = new AddDialogClass('form')
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.dataInfo = this.DROPDOWNBOX.device.filter(item => this.form.deviceId.includes(item.deviceId))
          this.$http.post(this.isEdit ? apis.waringUpdate : apis.waringSave, this.form).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.closeDialog()
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.post(apis.waringQueryData, data).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...res.data.rows,
            ...{
              deviceId: [res.data.rows.deviceId],
            },
          }
        }
      })
    },
    /**
     * @function 穿梭框搜索
     * @param {String} query 查询字符串
     * @param {Object} item 每个数据
     */
    filterMethod(query, item) {
      return item.deviceName.indexOf(query) > -1;
    },
  },
  activated() {
    this.getDevice()
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
