import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.alarmType = ''
      this.id = ''
      this.alarmContent = ''
      this.createBy = ''
      this.createTime = ''
      this.delFlag = ''
      this.deviceId = []
      this.deviceName = ''
      this.deviceType = ''
      this.param1 = ''
      this.param2 = ''
      this.status = ''
      this.telephone = ''
      this.triggerCondition = ''
      this.alarmContent = ''
    } else if (type === 'rule') {
      this.warehouseName = [
        {
          required: true,
          message: `${Lang[lanuage === 1 ? `en` : `zh`].ple_ent}${Lang[lanuage === 1 ? `en` : `zh`].warehouse_name}`,
          trigger: 'blur',
        },
      ]
      this.warehouseContact = [
        {
          required: true,
          message: `${Lang[lanuage === 1 ? `en` : `zh`].ple_ent}${Lang[lanuage === 1 ? `en` : `zh`].warehouse_contact}`,
          trigger: 'blur',
        },
      ]
      this.phone = [
        {
          required: true,
          message: `${Lang[lanuage === 1 ? `en` : `zh`].ple_ent}${Lang[lanuage === 1 ? `en` : `zh`].warehouse_phone}`,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
